.formContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: inherit;
}

.link {
  text-decoration: none;
}

.errorMsg {
  color: #FD0000;
}
