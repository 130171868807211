.questions-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 90%;
  margin-left: auto;
}

.questions-container div {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding-right: 5px;
}

.text-q {
  font-size: 1.2rem;
  color: #fc3030;
  font-weight: 500;
  line-height: 1.2;
}
