.batchProjectContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.projectNameContainer {
  width: 150px;
  text-align: left;
  display: flex;
  justify-content: left;
}
