.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info-box {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 5px;
  width: 30%;
  height: fit-content;
  padding: 20px;
  margin: 10px;
}

.left-box div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: left;
}

.left-box div p {
  padding-left: 5px;
}

.left-box {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.right-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  align-items: flex-end;
}

.right-box div {
  display: flex;
  flex-direction: column;
}

.btn {
  background-color: #fd0000;
  text-transform: none;
}
