.supportContainer {
    background-color: #F9F9F9;
    background-color: white;
    margin: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

