.projectFormContainer {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.dataGrid-container {
  max-width: 95%;
  margin: auto;
}
