.charts-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pie-container {
  height: 40%;
  width: 40%;
}

.bar-container {
  height: 100%;
  width: 100%;
  margin-top: 40px;
}

.clicked {
  color: red;
}
