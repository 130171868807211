.dateContainer {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: inherit;
}

.errorMsg {
  color: #fd0000;
}
