.drawerContainer {
  padding: 20px 30px;
}

.logo {
  width: 60px;
  text-align: left;
}

.logoText {
  color: #4c4a4a;
  margin-left: 10px;
  font-weight: bold;
}

.menuBottonsContainer {
    margin: 0 auto;
}

.accountCircleIconLink {
    margin-right: 10px;
}
